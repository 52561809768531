export const environment = {
  // In order to user "require" is needed enable option "resolveJsonModule" on tsconfig.json
  version: '1.0.1-snapshot',
  timestampBuild: '{BUILD_VERSION}',
  production: true,
  backendURL: 'https://disp.nextgen-lab.net/management-disp-service',
  // backendURL: 'https://disp.nextgen-lab.net/management-disp-service',
  backendService: '/management-disp-service',
  trackerURL: 'https://disp.nextgen-lab.net/tracking-disp-query',
  keycloakConfig: {
    redirectUri: 'https://disp.nextgen-lab.net',
    url: 'https://disp.nextgen-lab.net/auth/',
    realm: 'undss-local',
    clientId: 'undss-disp-app-local'
  },
  keycloakInitOptions: {
    // In case of offline access the following line needs to be removed. And if you want to check the scope result you will be offline, you can get it as follows: to add "offline_access" to the "client scopes" of your client (configure > clientst > {your client} > client scopes > evaluate > {click on "offline_access", "add selected" button and "evaluate" button}
    onLoad: 'login-required'
  }

};
