/**
 * My API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';

import {AssignCollectorToOrganization} from '../model/assignCollectorToOrganization';
import {Collector} from '../model/collector';
import {UnassignCollectorToOrganization} from '../model/unassignCollectorToOrganization';
import {UpdateCollectorInformation} from '../model/updateCollectorInformation';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class CollectorControllerService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = '';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   *
   *
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public assignCollectorToOrganization(body: AssignCollectorToOrganization, id: string, observe?: 'body', reportProgress?: boolean): Observable<Collector>;

  public assignCollectorToOrganization(body: AssignCollectorToOrganization, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Collector>>;

  public assignCollectorToOrganization(body: AssignCollectorToOrganization, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Collector>>;

  public assignCollectorToOrganization(body: AssignCollectorToOrganization, id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling assignCollectorToOrganization.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling assignCollectorToOrganization.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Collector>('patch', `${this.basePath}/collectors/${encodeURIComponent(String(id))}/organization`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCollectorAndTrackerAssociate(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public deleteCollectorAndTrackerAssociate(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public deleteCollectorAndTrackerAssociate(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public deleteCollectorAndTrackerAssociate(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteCollectorAndTrackerAssociate.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    /* const consumes: string[] = [
    ];*/

    return this.httpClient.request<any>('delete', `${this.basePath}/collectors/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public retrieveListCollectorsNotAssigned(observe?: 'body', reportProgress?: boolean): Observable<Array<Collector>>;

  public retrieveListCollectorsNotAssigned(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Collector>>>;

  public retrieveListCollectorsNotAssigned(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Collector>>>;

  public retrieveListCollectorsNotAssigned(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    /* const consumes: string[] = [
    ];*/

    return this.httpClient.request<Array<Collector>>('get', `${this.basePath}/collectors`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public unassignCollectorToOrganization(body: UnassignCollectorToOrganization, id: string, observe?: 'body', reportProgress?: boolean): Observable<Collector>;

  public unassignCollectorToOrganization(body: UnassignCollectorToOrganization, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Collector>>;

  public unassignCollectorToOrganization(body: UnassignCollectorToOrganization, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Collector>>;

  public unassignCollectorToOrganization(body: UnassignCollectorToOrganization, id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling unassignCollectorToOrganization.');
    }

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling unassignCollectorToOrganization.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Collector>('delete', `${this.basePath}/collectors/${encodeURIComponent(String(id))}/organization`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCollectorInformation(body: UpdateCollectorInformation, observe?: 'body', reportProgress?: boolean): Observable<Collector>;

  public updateCollectorInformation(body: UpdateCollectorInformation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Collector>>;

  public updateCollectorInformation(body: UpdateCollectorInformation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Collector>>;

  public updateCollectorInformation(body: UpdateCollectorInformation, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateCollectorInformation.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Collector>('put', `${this.basePath}/collectors`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

}
