/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {FrontOrganization} from '../models/entities/frontOrganization';
import * as __ from 'lodash';
import * as _ from 'underscore';
import * as moment from 'moment';

export class Utils {
  public static geti18nKey(fullKey: string): string | null {
    if (fullKey) {
      let parts: Array<string> = fullKey.split('.');
      if (parts.length > 0) {
        return parts[parts.length - 1];
      }
    }
    return null;
  }

  public static getEnumList(varEnum: any): Array<string> {
    if (varEnum) {
      try {
        return Object.keys(varEnum);
      } catch (e) {
      }
    }
    return [];
  }

  public static sortList(_list: Array<any>, orderBy: string, orderDirection: string): Array<any> {
    return Utils._sortList(_list, orderBy, orderDirection);
  }

  public static sortListSimple(_list: Array<string>, orderDirection: string): Array<string> {
    if (orderDirection == 'asc') {
      return __.sortBy(_list);
    } else if (orderDirection == 'desc') {
      return __.sortBy(_list).reverse();
    } else {
      console.error('#Bad sorting direction');
      return _list;
    }
  }

  public static sortNPageList(_list: Array<any>, orderBy: string, orderDirection: string, page: number, pageSize: number, totalItems: number) {
    return Utils._sortList(_list, orderBy, orderDirection, page, pageSize, totalItems);
  }

  public static isTextSeparatedBySpacesIncludingKey(str: string, key: string, inversed: boolean = false): boolean {
    if (!key || key.length == 0) {
      return true;
    } else if (!str || str.length == 0) {
      return false;
    } else {
      const arr = inversed ? key.split(' ') : str.split(' ');
      const keyLowerCase: string = (inversed ? str : key).toLowerCase();
      let found: number = 0;
      for (let item of arr) {
        if (inversed && keyLowerCase.includes(item.toLowerCase())) {
          found++;
        } else if (!inversed && item.toLowerCase().includes(keyLowerCase)) {
          found++;
        }
      }
      return found == arr.length;
    }
  }

  public static isTextIncludingKeySeparatedBySpaces(str: string, key: string): boolean {
    return Utils.isTextSeparatedBySpacesIncludingKey(str, key, true);
  }

  private static _sortList(_list: Array<any>, orderBy: string, orderDirection: string, page?: number, pageSize?: number, totalItems?: number) {
    let list = _list;
    //Sort
    try {
      if (orderDirection == 'asc') {
        //list = _.sortBy(list, orderBy);
        list = _.sortBy(list, (i) => i[orderBy].toLowerCase());
      } else if (orderDirection == 'desc') {
        // list = _.sortBy(list, orderBy).reverse();
        list = _.sortBy(list, (i) => i[orderBy].toLowerCase()).reverse();
      } else {
        console.warn('#Error sorting: no direction indicated');
      }
      // console.info('##Info: array sorted by "' + orderBy + '" and direction "' + orderDirection + '"');
    } catch (e) {
      console.error('##Error: not possible sort your array by "' + orderBy + '" and direction "' + orderDirection + '"');
    }

    if (pageSize && totalItems) {
      let retList = [];
      const init = (((page ? page : 0) - 1) * pageSize);  //-1 because arrays start from 0
      const end = init + pageSize - 1;        //-1 because arrays start from 0
      for (let i = init; i <= end && i <= totalItems && i < _list.length; i++) {
        retList.push(list[i]);
      }
      return retList;
    } else {
      return list;
    }
  }

  public static getMoment(dt: string | Date): moment.Moment {
    return moment(dt);
  }

  public static getDateFormat(str: string, format: string): moment.Moment {
    return moment(str, format, true);
  }

  public static getStringFromDateFormat(date: Date, format: string): string {
    return moment(date).format(format);
  }

  public static getCurrentMoment(): moment.Moment {
    return Utils.getMoment(new Date());
  }

  public static getDifferenceDatesInDays(date1: moment.Moment, date2: moment.Moment): number {
    return date1.diff(date2, 'days');
  }

  public static removeItemByIdInArray(arr: Array<any>, key: string, id: any): Array<any> {
    // _.reject(arr, function(item) { return item[key] === id});
    return arr.filter(obj => obj[key] !== id);
  }
}
