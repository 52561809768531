import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  ROUTE_PARAMETER_ID, ROUTE_URL_MAIN,
  ROUTE_URL_MAP,
  ROUTE_URL_MISSION,
  ROUTE_URL_ORGANIZATION,
  ROUTE_VERB_LIST
} from './core/services/navigation.service';

const routes: Routes = [
  {
    path: '', redirectTo: '/' + ROUTE_URL_ORGANIZATION + '/' + ROUTE_VERB_LIST, pathMatch: 'full'
  },
  {
    path: ROUTE_URL_MAIN,
    loadChildren: () => import('./main/main.module').then(m => m.MainModule)
  },
  {
    path: ROUTE_URL_MAP + '/:' + ROUTE_PARAMETER_ID,
    loadChildren: () => import('./map/map.module').then(m => m.MapModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]/*,
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]*/
})
export class AppRoutingModule {
}
