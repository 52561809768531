import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {AppConfigService} from '../config/app-config.service';
import {LocationResponse} from '../models/entities/LocationResponse';
import {KeycloakService} from './keycloak.service';
import { EventSourcePolyfill } from 'ng-event-source';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TrackerFluxService {
  private trackerWatchSource = new BehaviorSubject(new LocationResponse());
  private _trackerWatchSource: Observable<LocationResponse> = this.trackerWatchSource.asObservable();
  private missionsWatchUrl: string;
  private eventSource: EventSourcePolyfill;

  constructor(private appConfig: AppConfigService, private zone: NgZone, private keycloakService: KeycloakService, private http: HttpClient) {
  }

  public startFlux(missionId: string): Observable<LocationResponse> {
    this.missionsWatchUrl = this.appConfig.getTrackerURL().concat(this.appConfig.getWatchTrackerPath()) + '/' + missionId;
    this.getTrackerStream().subscribe(data => {
        //console.warn('#WebFlux tracker Watcher receiving!', data);
        this.trackerWatchSource.next(new LocationResponse().deserialize(data));
      }, error => {
        {
          console.error('#Error on tracker WebFlux: ' + error)
        }
      },
      () => {
        // console.log('#WebFlux tracker stream completed');
      });
    return this._trackerWatchSource;
  }

  private getTrackerStream(): Observable<LocationResponse> {
    return new Observable((observer) => {
      let eventSource = new EventSourcePolyfill(this.missionsWatchUrl, {
        // @ts-ignore
        headers: {
          Authorization: 'Bearer ' + this.keycloakService.getToken() || ''
        },
        heartbeatTimeout: 60 * 60 * 1000,
        connectionTimeout: 60 * 60 * 1000
      });

      eventSource.onmessage = (event: any) => {
        let json = JSON.parse(event.data);
        if (json !== undefined && json !== '') {
          this.zone.run(() => observer.next(json));
        }
      };

      eventSource.onerror = (error: any) => {
        if (eventSource.readyState === 0) {
          console.error('The tracker stream has been closed by the server.');
          eventSource.close();
          observer.complete();
        } else {
          observer.error('EventSource tracker error: ' + error);
        }
      }
    });
  }

  public listVehicles(missionId: string): Observable<LocationResponse[]> {
    let url = this.appConfig.getTrackerURL().concat(this.appConfig.getListTrackersPath()) + '/' + missionId;
    return this.http.get<LocationResponse[]>(url, {
      headers: {
        Accept: 'application/json'
      }
    });
  }
}
