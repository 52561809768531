export const ROUTE_URL_INFO = 'info';
export const ROUTE_URL_MAIN = '';
export const ROUTE_URL_ORGANIZATION = 'organization';
export const ROUTE_URL_MAP = 'map';
export const ROUTE_URL_MISSION = 'mission';
export const ROUTE_VERB_LIST = 'list';
export const ROUTE_VERB_EDIT = 'edit';
export const ROUTE_VERB_NEW = 'new';
export const ROUTE_VERB_LINK = 'link';
export const ROUTE_PARAMETER_ID: string = 'id';

export const ROUTE_FULL: string = 'full';
