import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, concatMap, delay, retryWhen} from 'rxjs/operators';
import {KeycloakService} from '../services/keycloak.service';
import {AppConfigService} from '../config/app-config.service';
import {ToastService} from '../services/toast-service';
import {TranslateService} from '@ngx-translate/core';
import {ExtendedHttpErrorResponse} from './extended-http-error-response';
import {Utils} from '../tools/utils';

export const retryCount = 3;
export const retryWaitMilliSeconds = 1000;

@Injectable()
export class KeycloakTokenInterceptor implements HttpInterceptor {
  constructor(
    private keycloakService: KeycloakService,
    private appConfig: AppConfigService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const authToken = this.keycloakService.getToken() || '';
    request = request.clone({
      url: (request.url.startsWith(this.appConfig.getBackendService()) ? this.appConfig.getBackendURL() : '') + request.url,
      setHeaders: {
        Authorization: 'Bearer ' + authToken
      }
    });

    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (this.keycloakService.isTokenExpired()) {
            console.log('#Token expired#');
            // When NOT idle and got a new URL a new token could be renewed
            return this.keycloakService.renewToken(false).then(() => {
              // Recall the original call after (retrying until 3 (retryCountry) times)
              return next.handle(request).pipe(
                retryWhen(error =>
                  error.pipe(
                    concatMap((error, count) => {
                      if (count <= retryCount && error.status == 503) {
                        return of(error);
                      }
                      return throwError(error);
                    }),
                    delay(retryWaitMilliSeconds)
                  )
                ));
            }).catch((err: any) => {
              console.log(err);
              return throwError(this.wrapError(error));
            });

          } else {
            return throwError(this.wrapError(error));
          }
        })
      )
  }

  private wrapError(errorResponse: any): ExtendedHttpErrorResponse {
    let err = new ExtendedHttpErrorResponse(errorResponse);
    let preErrorMsg;
    let errorMsg;
    if (errorResponse.error && typeof errorResponse.error === 'string' && this.isi18nContent(errorResponse.error)) {
      err.errorDescription = errorResponse.error;
    } else if (errorResponse.error != null && errorResponse.error.error != null && typeof errorResponse.error.error === 'string' && this.isi18nContent(errorResponse.error.error)) {
      err.errorDescription = errorResponse.error.error;
    } else if (errorResponse.error instanceof ErrorEvent) {
      preErrorMsg = '# Client side error';
      errorMsg = `Error: ${errorResponse.error.message}`;
      err.errorDescription = errorMsg;
      this.toastService.showToasterError('Service (client) is down or error found')

      // error.error instanceof ProgressEvent
    } else {
      preErrorMsg = '# Server side error';
      errorMsg = `Error Code: ${errorResponse.status},  Message: ${errorResponse.message}`;
      let errDescription: string;
      if (errorResponse.status == 403) {
        errDescription = 'You are not allowed to access this service';
      } else {
        errDescription = 'Service is down or error found';
      }
      err.errorDescription = Utils.geti18nKey(errDescription);
      this.toastService.showToasterError(this.translateService.instant(errDescription))
    }
    return err;
  }

  private isi18nContent(str: string): boolean {
    return str != null && !(str.trim()).includes(' ') && str.includes('_') && str.trim().length <= 50;
  }
}
