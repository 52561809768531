/**
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 *
 * Defined using the JSON model located at "external-resources-not-dist/auth-jwt/auth-jwt-example.json"
 */
export class KeycloakTokenModel {
  acr: string;
  'allowed-origins': string[]; // i.e. ["http://localhost:4200"]
  auth_time: number;
  azp: string;  // clientId: i.e. "mvp-pulse3"
  email: string;
  email_verified: boolean;
  exp: number;  // i.e. 1629285999
  family_name: string;
  given_name: string;
  iat: number;
  iss: string;  // url: i.e. "http://localhost:8080/auth/realms/undss-disp-app-local"
  jti: string;  // i.e. 999b96b3-91bb-4cdb-967a-4c1f08752992
  name: string;
  nonce: string;
  preferred_username: string; // i.e. "usertest" || "user@test.com""
  realm_access: { roles: string[] };  // roles: {roles: ["USER"]}
  scope: string;  // i.e. "openid profile email roles"
  session_State: string;  // i.e. "999b96b3-91bb-4cdb-967a-4c1f08752999"
  aud: string;  // i.e. "account"
  sub: string;  // user id: i.e. "999b96b3-91bb-4cdb-967a-4c1f08752991"
  typ: string;  // i.e. "Bearer"

  public parseFromJson(json: any): KeycloakTokenModel {
    const authUserModel: KeycloakTokenModel = Object.assign(new KeycloakTokenModel(), JSON.parse(json));  // Supported by ES6
    return authUserModel;
  }
}
