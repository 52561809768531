import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {AppConfigService} from '../config/app-config.service';
import {Mission} from '../models/entities/Mission';
import {KeycloakService} from './keycloak.service';
import { EventSourcePolyfill } from 'ng-event-source';

@Injectable({
  providedIn: 'root'
})
export class MissionFluxService {
  private missionWatchSource = new BehaviorSubject(new Mission());
  private _missionWatchSource: Observable<Mission> = this.missionWatchSource.asObservable();
  private missionsWatchUrl: string;
  private eventSource: EventSourcePolyfill;

  constructor(private appConfig: AppConfigService, private zone: NgZone, private keycloakService: KeycloakService) {
  }

  public startFlux(): Observable<Mission> {
    this.missionsWatchUrl = this.appConfig.getBackendURL().concat(this.appConfig.getWatchMissionsPath());
    this.getMissionsStream().subscribe(data => {
        // console.warn('#WebFlux mission Watcher receiving!', data);
        this.missionWatchSource.next(new Mission().deserialize(data));
      }, error => {
        {
          console.error('#Error on mission WebFlux: ' + error)
        }
      },
      () => {
        // console.log('#WebFlux mission stream completed');
      });
    return this._missionWatchSource;
  }

  private getMissionsStream(): Observable<Mission> {
    return new Observable((observer) => {
      let url = this.missionsWatchUrl;
      let eventSource = new EventSourcePolyfill(url, {
        // @ts-ignore
        headers: {
          Authorization: 'Bearer ' + this.keycloakService.getToken() || ''
        },
        heartbeatTimeout: 60 * 60 * 1000,
        connectionTimeout: 60 * 60 * 1000
      });

      eventSource.onmessage = (event: any) => {
        let json = JSON.parse(event.data);
        if (json !== undefined && json !== '') {
          this.zone.run(() => observer.next(json));
        }
      };

      eventSource.onerror = (error: any) => {
        if (eventSource.readyState === 0) {
          console.error('The mission stream has been closed by the server.');
          eventSource.close();
          observer.complete();
        } else {
          observer.error('EventSource mission error: ' + error);
        }
      }
    });
  }
}
