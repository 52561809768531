import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {KeycloakService} from './core/services/keycloak.service';
import {ROUTE_URL_INFO} from './core/services/navigation.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {KeycloakTokenInterceptor} from './core/interceptors/keycloak-token-interceptor.service';
import {TranslateModule} from '@ngx-translate/core';
import {MatGridListModule} from "@angular/material/grid-list";
import {MatCardModule} from "@angular/material/card";
import {AppConfigService} from "./core/config/app-config.service";
import {MissionFluxService} from './core/services/mission-flux.service';
import {ToastService} from './core/services/toast-service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {OrganizationControllerService} from './core/services/directoryservice/api/organizationController.service';
import {MissionControllerService} from './core/services/directoryservice/api/missionController.service';
import {Configuration, ConfigurationParameters} from './core/services/directoryservice/configuration';
import {ApiModule} from './core/services/directoryservice/api.module';
import {environment} from '../environments/environment';
import {TrackerControllerService} from './core/services/directoryservice/api/trackerController.service';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {DialogService} from './core/services/dialog-service';
import {ConfirmDialogComponent} from './core/dialogs/confirm-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {TrackerFluxService} from './core/services/tracker-flux.service';
import {GoogleMapsModule} from '@angular/google-maps';
import {SharedModule} from './shared/shared.module';
import {MatButtonModule} from '@angular/material/button';

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.backendURL, //TODO It will work on DEV/PROD or needed to add "environment.backendService"?
    /*accessToken: () => {
      return '';
    }*/
  };
  return new Configuration(params);
}

export function kcFactory(keycloakService: KeycloakService): any {
  if (document.location.href.endsWith('/' + ROUTE_URL_INFO)) {    // TODO: Pending doing it in a modern and cleaner way (this.router.url)
    return () => {
    }
  }
  return () => keycloakService.init();
}

@NgModule({
  declarations: [
    AppComponent,
    ConfirmDialogComponent,
    // DialogComponent,
    // UppercaseInputDirective
  ],
  entryComponents: [
    ConfirmDialogComponent
  ],
  imports: [
    SharedModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    GoogleMapsModule,
    AppRoutingModule,
    ApiModule.forRoot(apiConfigFactory),
    FormsModule,
    TranslateModule.forRoot(),
    MatGridListModule,
    MatCardModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule
  ],
  providers: [
    // Keycloak
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: kcFactory,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakTokenInterceptor,
      multi: true
    },
    ToastService,
    DialogService,
    /** Backend Controller Services **/
    MissionFluxService,
    TrackerFluxService,
    OrganizationControllerService,
    MissionControllerService,
    AppConfigService,
    TrackerControllerService
  ],
  exports: [
    ConfirmDialogComponent,
    // UppercaseInputDirective
  ],
  bootstrap: [AppComponent],/*
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]*/
})
export class AppModule {
}
